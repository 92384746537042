import React from "react";
import Container from "../components/container";
import Intro from "../components/intro";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import Footer from "../components/footer";
import PageBody from "../components/page-body";

export default function About({ pageContext, data: { site, main, about } }) {

    const pc = pageContext

    return (
        <Container>
            <HelmetDatoCms seo={main.seo} favicon={site.favicon} />
            <Intro pageContext={pc} />
            <div className="md:grid md:grid-cols-4 md:gap-4">
                <div className="col-span-3">
                    <PageBody body={about.body}></PageBody>
                </div>
                <div className="flex">
                    <div className="justify-center">
                        <GatsbyImage className="m-3" image={about.photo.small} />
                    </div>
                </div>
            </div>
            <Footer />

        </Container>
    );
}

export const query = graphql`
  query page($locale: String!){
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    main: datoCmsMain {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    about: datoCmsAbout(locale: {eq: $locale}) {
        body,
        photo {
            small: gatsbyImageData(width: 760)
        }
    }
  }
`;
